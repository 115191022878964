import singleSpaHtml from "single-spa-html";
import template from "./template.html"

console.log(template);

const htmlLifecycles = singleSpaHtml({
  // domElementGetter: () => {
  //   const id = "single-spa-application:@binaria/results-spa";
  //   let container = document.getElementById(id);
  //   if (!container) {
  //     container = document.createElement("div");
  //     container.id = id;
  //     document.body.prepend(container); // single-spa automatically _appends_, but this content should be _prepended_ for accessibility
  //   }
  //   return container;
  // },
  template: template,
});

export const mount = async (props) => {
  await htmlLifecycles.mount(props);
  let payload = localStorage.getItem("FlowResult");
  console.log(payload);
};

export const { bootstrap, unmount } = htmlLifecycles; // export other lifecycles as-is
